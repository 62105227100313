import { isEmpty, isNil, last } from "lodash";

const AWS_STG_CLOUDFRONT_KEY =
  process.env.AWS_STG_CLOUDFRONT_KEY || "d14bfybqzkvysv";
const AWS_PROD_CLOUDFRONT_KEY =
  process.env.AWS_PROD_CLOUDFRONT_KEY || "d29unzfbsc4uyg";
const AWS_STG_S3_BUCKET =
  process.env.AWS_STG_S3_BUCKET || "pencil-staging-bucket";
const AWS_PROD_S3_BUCKET =
  process.env.AWS_PROD_S3_BUCKET || "pencil-production-bucket";

const CloudFrontS3Mapping = {
  [AWS_STG_S3_BUCKET]: AWS_STG_CLOUDFRONT_KEY,
  [AWS_PROD_S3_BUCKET]: AWS_PROD_CLOUDFRONT_KEY,
};

export enum R2HOST {
  STAGING_CDN = 'stg.trypncl.com',
  PRODUCTION_CDN = 'cms.trypncl.com',
  OPTIMIZED_CDN = 'opt.trypncl.com',
}

export const R2Mapping = {
  [AWS_STG_S3_BUCKET]: R2HOST.STAGING_CDN,
  [AWS_PROD_S3_BUCKET]: R2HOST.PRODUCTION_CDN,
};

/**
 * Supported format: https://<bucket>.s3.<region>?.amazonaws.com/<key>?<qs>
 * @param presignedUrl S3 url
 * @returns s3Key, s3Bucket
 *
 */
export const extractS3KeyBucketFromUrl = (url: string) => {
  try {
    const s3Regex = /^https?:\/\/(.+)?.s3(.+)?.amazonaws\.com\/(.+)/;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, s3Bucket, region, s3Key]: any = url.split("?")[0].match(s3Regex);

    return {
      s3Bucket,
      s3Key,
      region,
    };
  } catch {
    try {
      const cloudFrontRegex = '^https?://([^.]+).cloudfront.net/(.*?)$';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, cfKey, s3Key] = url.split('?')[0].match(cloudFrontRegex);

      return {
        s3Key,
        s3Bucket: CloudFrontS3Mapping[cfKey],
      };
    } catch {
      console.error(`Failed to extract ${url}`);
    }
  }

  return {
    s3Bucket: null,
    s3Key: null,
  };
};

export const getCloudFrontUrl = (s3Bucket: string = AWS_PROD_S3_BUCKET, s3Key: string): string => {
  const cloudfront = CloudFrontS3Mapping[s3Bucket];

  return `https://${cloudfront}.cloudfront.net/${s3Key}`;
}

export const convertToCloudFrontUrl = (url: string): string => {
  // Skip cloudfront converting if signed url
  const urlParts = url.split("?");
  const queryParts = last(urlParts) ?? "";
  if (urlParts.length > 1 && !isEmpty(queryParts)) {
    return url;
  }

  const { s3Bucket, s3Key } = extractS3KeyBucketFromUrl(url);
  if (isNil(s3Bucket)) {
    return url;
  }

  return getCloudFrontUrl(s3Bucket, s3Key);
};

export function getUrlForPublicObject(
  s3Key: string,
  s3Bucket = AWS_PROD_S3_BUCKET,
  enabledR2: boolean = true,
) {
  if (enabledR2) {
    const r2 = R2Mapping[s3Bucket];

    return `https://${r2}/${s3Key}`;
  }

  return `https://${s3Bucket}.s3.amazonaws.com/${s3Key}`;
}