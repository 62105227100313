import { isNil } from "lodash";
import { useCallback } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { HttpMethod } from "../../../common/enums";
import { useHttpClient } from "../../../common/http-client/use-http-client";
import { SaveTemplateSetRequest, Template } from "../../../types";

// Validate Templates
// - Force to update all Text to have Autofit on
const validateTemplates = (_templates: Template[]) => {
  const templates = _templates.map(t => {
    const scenes = (t.visualJson.scenes ?? []).map(s => {
      const layers = s.layers.map(l => {
        if (l.type === "text") {
          console.info(`Found text layer: ${l.id}-${l.name}. Force autoFit to True.`)
          l.autofit = true;

          return l;
        }

        return l;
      })

      return {
        ...s,
        layers
      };
    });


    return {
      ...t,
      visualJson: {
        ...t.visualJson,
        scenes
      }
    }
  });

  return templates;
}


export const useSaveTemplateSet = () => {
  const client = useHttpClient();
  const { templateSetId } = useParams();

  const saveTemplateSet = useCallback((reqData: SaveTemplateSetRequest) => {
    if (!client) {
      return;
    }

    // Validating Templates
    reqData.templates = validateTemplates([...reqData.templates]);

    // Create Template Set request
    if (isNil(templateSetId)) {
      return client({
        url: '/api/templateSetV4',
        method: HttpMethod.POST,
        data: reqData
      })
    }

    // Update Template Set request
    return client({
      url: `/api/templateSetV4/${templateSetId}`,
      method: HttpMethod.PUT,
      data: reqData
    })
  }, [client, templateSetId])


  return useMutation((data: SaveTemplateSetRequest) => saveTemplateSet(data))
}

export const useUpdateTemplateSet = (templateSetId: number) => {
  const client = useHttpClient();

  const updateTemplateSet = useCallback(
    (templateSetId: number, reqData: SaveTemplateSetRequest) => {
      if (!client) {
        return;
      }

      // Validating Templates
      reqData.templates = validateTemplates([...reqData.templates]);

      // Update Template Set request
      return client({
        url: `/api/templateSetV4/${templateSetId}`,
        method: HttpMethod.PUT,
        data: reqData,
      });
    },
    [client]
  );

  return useMutation((data: SaveTemplateSetRequest) =>
    updateTemplateSet(templateSetId, data)
  );
};