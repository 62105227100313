import { useSaveTemplateSet } from '../hooks/use-save-template-set';
import { useEditorPage } from '../hooks/use-editor-page';
import { isNil, omit } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  AdData,
  EditorMode,
  EnterpriseEditor,
  TemplateType,
  TemplateCategoryType,
} from '@btg-pencil-ai/editor';
import { useBlockingUI } from '../../../common/hooks/use-blocking-ui';
import { SpinnerLarge } from '../../../common/components-ui/spinner/spinner-large';
import { useGetAssetList } from '../hooks/use-get-asset-list';
import { useGetStickerList } from '../hooks/use-get-sticker-list';
import { useGetLogoList } from '../hooks/use-get-logo-list';
import { useApplySampleBrand } from '../hooks/use-apply-sample-brand';
import { useUpdateTemplateSet } from '../../templates/hooks/use-update-template-set';
import BlockUi from '@availity/block-ui';
import { useCallback, useState } from 'react';
import { useShopifyAppHook } from '../hooks/use-shopify-app-hook-sample';
import { env } from '../../../common/envConfigs';
import { usePreloadFonts } from '../../../common/hooks/use-preload-fonts';

const EditorPage = () => {
  const { blocking, setBlocking } = useBlockingUI();
  const navigate = useNavigate();
  const {
    templateSetData,
    templateType,
    isReady: isDataReady,
    useBrand,
    templateCategoryId,
  } = useEditorPage();
  const { mutateAsync: saveTemplateSet } = useSaveTemplateSet();
  const { assetList } = useGetAssetList();
  const { stickerList } = useGetStickerList();
  const { logoList } = useGetLogoList();
  const { mutateAsync: applySampleBrand } = useApplySampleBrand();
  const { mutateAsync: updateApproval } = useUpdateTemplateSet({
    filters: undefined,
  });

  const [isEditorLoaded, setIsEditorLoaded] = useState(false);

  const { isFontLoaded, fontListWithUrl } = usePreloadFonts({
    clientId: env.BRAND_CLIENT_ID,
  });

  const isEditorReady = isDataReady && isEditorLoaded && isFontLoaded;
  const isCreateNewTemplate = isDataReady && isNil(templateSetData?.templates);

  const onEditorSave = useCallback(
    async (
      {
        listAdJsons: templates,
        name,
        categoryIds,
        approved
      }: {
        listAdJsons: any[];
        name?: string;
        categoryIds?: number[];
        approved?: boolean;
      }
    ) => {
      setBlocking(true);
      const resp: any = await saveTemplateSet({
        name: name ?? 'Test Template Set',
        templates: templates.map((t) => {
          const dimensions = {
            width: t.dimensions.width,
            height: t.dimensions.height,
          };

          if (isNil(t.id)) {
            return {
              visualJson: t,
              dimensions,
            };
          }

          return {
            id: t.id,
            visualJson: omit(t, 'id'),
            dimensions,
          };
        }),
        categoryIds,
      });

      const templateSetId = templateSetData?.id ?? resp.newTemplateSet?.id;

      if (templateSetId) {
        await updateApproval({
          setId: templateSetId,
          isApproved: Boolean(approved),
          applySkin: true,
        });
      }

      setBlocking(false);

      navigate('/');
    },
    [
      navigate,
      saveTemplateSet,
      setBlocking,
      templateSetData?.id,
      updateApproval,
    ]
  );

  return (
    <>
      <div className="relative">
        <BlockUi
          tag="div"
          className="w-screen h-screen z-10"
          blocking={!isEditorReady || blocking}
          loader={<SpinnerLarge />}
        >
          {isDataReady && (
            <EnterpriseEditor
              ads={templateSetData?.templates ?? null}
              isCreatingNew={isCreateNewTemplate}
              templateType={templateType as TemplateType}
              templateCategory={
                TemplateCategoryType[
                  Object.keys(TemplateCategoryType).at(templateCategoryId)
                ]
              }
              useShopifyAppHook={useShopifyAppHook}
              onEditorLoaded={() => {
                setIsEditorLoaded(true);
              }}
              useR2={true}
              metadata={{
                name: templateSetData?.name,
                defaultAssets: assetList,
                defaultStickers: stickerList,
                defaultLogos: logoList,
                customFonts: fontListWithUrl,
              }}
              templateCategoryIds={templateSetData?.categoryIds}
              mode={EditorMode.TEMPLATE_BUILDER}
              {...(!useBrand && { onEditorSave })}
              onCancel={() => navigate('/')}
              applyBrand={(brandName: string, adData: AdData) => {
                const template = templateSetData?.templates.find(
                  (t) => t.dimensions.id === adData.dimensions.id
                );

                return applySampleBrand({
                  templateSetId: template?.id,
                  brandName,
                  adData,
                });
              }}
            />
          )}
        </BlockUi>
      </div>
    </>
  );
};

export default EditorPage;
