import './styles/globals.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AlertsContainer, showAlert } from './common/alert/alerts-manager';
import { useEffect, useMemo } from 'react';
import {
  tryPersistWithoutPromtingUser,
  persist,
  getEstimateQuota,
} from '@btg-pencil-ai/editor';
import { ErrorType, LocalStorageKeys } from './common/enums';
import SessionProvider from './common/context/session';

function App() {
  const queryClient = useMemo(() => {
    const onError = (error: any) => {
      const errMsg = error.message;

      if (errMsg === ErrorType.UNAUTHORIZED) {
        window.location.href = window.location.origin + '/login';
        localStorage.removeItem(LocalStorageKeys.JWT_TOKEN);

        return;
      }


      showAlert({
        type: 'error',
        message: errMsg,
      });
    };

    const retry = (failureCount: number, error: any) => {
      const errMsg = error.message;

      if ([ErrorType.UNAUTHORIZED, ErrorType.INVALID_CREDS].includes(errMsg) || failureCount >= 2) {
        return false;
      }

      return true;
    };

    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          onError,
          retry,
        },
        mutations: {
          onError,
          retry
        },
      },
    });
  }, []);

  useEffect(function initStoragePersistence() {
    tryPersistWithoutPromtingUser().then((persistMsg) => {
      switch (persistMsg) {
        case 'never':
          console.info('Not possible to persist storage');
          break;
        case 'persisted':
          console.info('Successfully persisted storage silently');
          getEstimateQuota().then((quotaInfo) => console.info(quotaInfo));
          break;
        case 'prompt':
          console.info(
            'Not persisted, but we may prompt user when we want to.'
          );
          persist().then(() =>
            getEstimateQuota().then((quotaInfo) => console.info(quotaInfo))
          );

          break;
      }
    });
  }, []);

  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <AlertsContainer />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </SessionProvider>
  );
}

export default App;
